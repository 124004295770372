import { getLogger } from '@engined/core/services/logger.js';
import { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete.js';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams,
  InputProps as StandardInputProps,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';
import React, { useState } from 'react';
import { Control, useController, useFormState } from 'react-hook-form';

type fixme = any;

const logger = getLogger('components/forms/fields/AutocompleteField');

export interface Option<TData = any> {
  label: string;
  value: string;
  virtual?: boolean;
  data?: TData; // Data from API
}

export type ReplaceOptionsInValues<Values> = {
  [K in keyof Values]?: Values[K] extends Option[] ? string[] : Values[K] extends Option ? string : Values[K];
};

const defaultGetOptionLabel: fixme = (option: Option) => option.label;
const defaultGetOptionSelected = (option: Option, value: Option) => option.value === value.value;

interface OwnProps<
  TOption extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> {
  name: string;
  renderInput?: (params: MuiAutocompleteRenderInputParams) => React.ReactNode;
  inputAutoFocus?: boolean;
  inputAutoComplete?: string;
  inputLabel?: React.ReactNode;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  inputRequired?: boolean;
  inputHelperText?: React.ReactNode;
  inputInputProps?: Partial<StandardInputProps>;
  control?: Control;
  canChange?(
    value: AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
  ): boolean;
}

export type Props<
  TOption extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = OwnProps<TOption, Multiple, DisableClearable, FreeSolo> &
  Omit<MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>, 'renderInput'>;

function AutocompleteField<
  TOption extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  onBlur,
  onInputChange,
  onChange,
  canChange,
  freeSolo,
  renderInput,
  options,
  getOptionLabel = defaultGetOptionLabel,
  isOptionEqualToValue,
  multiple,
  disableClearable,
  inputLabel,
  inputVariant,
  inputRequired,
  inputHelperText,
  inputAutoFocus,
  inputAutoComplete,
  inputInputProps,
  loading,
  control,
  ...rest
}: Props<TOption, Multiple, DisableClearable, FreeSolo>) {
  const { field } = useController({
    control,
    name,
  });

  const [inputValue, setInputValue] = useState<string>(field.value?.label ?? '');

  if (process.env.NODE_ENV !== 'production') {
    if (multiple && !Array.isArray(field.value)) {
      logger.warn(`value for ${field.name} is not an array, this can caused unexpected behaviour`);
    }
  }

  const onBlurCallback: MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>['onBlur'] = (event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  };

  const onInputChangeCallback: MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>['onInputChange'] = (
    event,
    value,
    reason,
  ) => {
    if (onInputChange) {
      onInputChange(event, value, reason);
    }
    if (freeSolo) {
      field.onChange(value);
    }
    setInputValue(value);
  };

  const onChangeCallback: MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>['onChange'] = (
    event,
    value,
    reason,
    details,
  ) => {
    if (onChange) {
      onChange(event, value, reason, details);
    }
    if (!freeSolo && !details?.option?.virtual && (canChange?.(value, reason) ?? true)) {
      field.onChange(value);
    }
  };

  const renderInputCallback: MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>['renderInput'] = (
    params,
  ) => {
    if (renderInput) {
      return renderInput(params);
    }

    return (
      <AutocompleteTextInput
        {...params}
        fieldName={name}
        name={name}
        label={inputLabel}
        variant={inputVariant}
        required={inputRequired}
        autoFocus={inputAutoFocus}
        autoComplete={inputAutoComplete}
        startAdornment={inputInputProps?.startAdornment}
        endAdornment={inputInputProps?.endAdornment}
        helperText={inputHelperText}
      />
    );
  };

  const renderOption: MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>['renderOption'] = (
    props,
    option,
  ) => (
    // Note: We are overwriting key because props.key is based on getOptionLabel
    // which can contains duplicates
    <li {...props} key={(option.virtual ? '_' : '') + option.value}>
      {getOptionLabel(option)}
    </li>
  );

  return (
    <MuiAutocomplete
      onBlur={onBlurCallback}
      freeSolo={freeSolo}
      onInputChange={onInputChangeCallback}
      onChange={onChangeCallback}
      renderInput={renderInputCallback}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue ?? defaultGetOptionSelected}
      options={options}
      value={field.value}
      multiple={multiple}
      disableClearable={disableClearable}
      loading={loading}
      id={name}
      autoHighlight
      // inputValue={inputValue}
      {...rest}
    />
  );
}

AutocompleteField.displayName = 'AutocompleteField';

export default React.memo(AutocompleteField);

interface AutocompleteTextInputOwnProps {
  fieldName: string;
  endAdornment: React.ReactNode;
  startAdornment: React.ReactNode;
}

let AutocompleteTextInput: React.FC<AutocompleteTextInputOwnProps & TextFieldProps> = ({
  fieldName,
  endAdornment,
  startAdornment,
  InputProps,
  ...rest
}) => {
  const { errors } = useFormState({ name: fieldName });

  let newInputProps: TextFieldProps['InputProps'] = InputProps;
  if (startAdornment || endAdornment) {
    const givenEndAdornment = InputProps?.endAdornment;
    const givenStartAdornment = InputProps?.startAdornment;
    newInputProps = {
      name: fieldName,
      ...InputProps,
      endAdornment: endAdornment ? (
        React.isValidElement<{ children?: React.ReactNode[] }>(givenEndAdornment) ? (
          React.cloneElement(givenEndAdornment, {
            children: [...givenEndAdornment.props.children, endAdornment],
          })
        ) : (
          <>
            {givenEndAdornment} {endAdornment}
          </>
        )
      ) : (
        givenEndAdornment
      ),
      startAdornment: startAdornment ? (
        React.isValidElement<{ children?: React.ReactNode[] }>(givenStartAdornment) ? (
          React.cloneElement(givenStartAdornment, {
            children: [startAdornment, ...givenStartAdornment.props.children],
          })
        ) : (
          <>
            {givenStartAdornment} {startAdornment}
          </>
        )
      ) : (
        givenStartAdornment
      ),
    };
  }

  const showError = !!errors[fieldName];

  return (
    <MuiTextField
      {...rest}
      error={showError}
      helperText={showError ? (errors[fieldName]?.message as string | undefined) : rest.helperText}
      InputProps={newInputProps}
    />
  );
};

AutocompleteTextInput.displayName = 'AutocompleteTextInput';
AutocompleteTextInput = React.memo(AutocompleteTextInput);
